<template>
  <form-field v-bind="formFieldAttrs" :validate-value="value">
    <dropdown
      :disabled="disabled"
      :value="value"
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      :no-selection="noSelection"
      @input="$emit('input', $event)"
    >
    </dropdown>
  </form-field>
</template>

<script>
import formFieldProps from "../mixins/form-field";

export default {
  mixins: [formFieldProps],

  props: {
    value: { default: "" },
    items: { type: Array, required: true },
    itemText: { type: String, default: "text" },
    itemValue: { type: String, default: "value" },
    noSelection: { type: String, default: "(select option)" },
  },
};
</script>
