<template>
  <div class="obe-calendar">
    <table
      class="obe-calendar__table"
      cellpadding="0"
      cellspacing="0"
      border="0"
    >
      <thead>
        <tr class="obe-calendar__month-row">
          <td colspan="7">
            <div class="obe-calendar__title">
              <div class="obe-calendar__month-nav">
                <a
                  href="#"
                  class="obe-calendar__month-prev"
                  @click.prevent="changeMonth(-1)"
                >
                  <span class="obe-sr-only">Previous Month</span>
                  <span class="obe-i-left-arrow-bold"></span>
                </a>
              </div>

              <div class="obe-calendar__month-name">{{ title }}</div>

              <div class="obe-calendar__month-nav">
                <a
                  href="#"
                  class="obe-calendar__month-next"
                  @click.prevent="changeMonth(1)"
                >
                  <span class="obe-sr-only">Next Month</span>
                  <span class="obe-i-right-arrow-bold"></span>
                </a>
              </div>
            </div>
          </td>
        </tr>
        <tr class="obe-calendar__days-row">
          <td>Sun</td>
          <td>Mon</td>
          <td>Tue</td>
          <td>Wed</td>
          <td>Thu</td>
          <td>Fri</td>
          <td>Sat</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowNum) in calendarDates" :key="rowNum">
          <td v-for="(cell, index) in row" :key="index">
            <div
              class="obe-calendar__cell"
              :class="{ 'obe-calendar__cell-trailing': !cell.isCurrentMonth }"
            >
              <a
                href="#"
                :class="getCellClasses(cell)"
                @click.prevent="selectDate(cell.date, true)"
                v-if="isValidDate(cell.date)"
                >{{ cell.text }}</a
              >
              <span
                class="obe-calendar__invalid-date"
                v-if="!isValidDate(cell.date)"
              >
                {{ cell.text }}
              </span>

              <!--
							<a href="#" :class="getCellClasses(cell)" @click.prevent="selectDate(cell.date)" v-if="isValidDate(cell.date)">{{ cell.text }}</a>
							<span :class="getInvalidCellClasses(cell)" v-if="!isValidDate(cell.date)">
								{{ cell.text }}
								<span class="invalid-hover-tooltip" v-if="isBlockedDate(cell.date)">Sorry, this date is not available</span>
							</span>
							-->
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
//import filters from 'common/mixins/filters';
import moment from "moment";

const DATE_FORMAT = "YYYY-MM-DD";

export default {
  props: {
    value: { type: String, default: moment().format(DATE_FORMAT) },
    minDate: { type: String, default: "" },
    checkDate: { type: Function },
  },

  data() {
    return {
      checkingAvailableDates: false,
      unavailableDates: [new Date()],
      month: moment().month(),
      year: moment().year(),
    };
  },

  computed: {
    currentDate() {
      var date = moment(this.value, DATE_FORMAT);
      var minDate = moment(this.value, DATE_FORMAT);

      // if date is not valid then use the current date
      if (!date.isValid()) date = moment();

      return this.clampToMinDate(date);
    },

    viewingDate() {
      return moment().month(this.month).year(this.year);
    },

    title() {
      return this.viewingDate.format("MMMM YYYY");
    },

    // get days to render
    calendarDates() {
      var month = this.month;
      var startDate = moment(this.viewingDate).date(1).day(0);
      var endDate = moment(this.viewingDate).month(month).endOf("month").day(6);
      var current = startDate,
        dates = [],
        index = 0;
      var rows = [],
        row = [];

      // calculate dates
      while (current.isSameOrBefore(endDate)) {
        row.push({
          //text: sprintf.sprintf('%02d', current.date()),
          trailing: current.month() != month,
          text: current.format("D"),
          date: moment(current),
          index: ++index,
          isCurrentMonth: current.month() == month,
          isSelected: current.format(DATE_FORMAT) == this.value,
          //isValid: this.isValidDate(current),
        });

        if (row.length == 7) {
          rows.push(row);
          row = [];
        }

        current.add(1, "d");
      }

      if (row.length > 0) rows.push(row);

      return rows;
    },
  },

  watch: {
    minDate() {
      var date = this.clampToMinDate(this.viewingDate);
      this.month = date.month();
      this.year = date.year();
    },

    value() {
      this.month = this.currentDate.month();
      this.year = this.currentDate.year();
    },
  },

  methods: {
    clampToMinDate(date) {
      var min = moment(this.minDate, DATE_FORMAT);
      if (min.isValid() && date.isBefore(min)) return min;
      else return date;
    },

    isValidDate(date) {
      var min = moment(this.minDate, DATE_FORMAT);

      // first run check date function if specified
      if (
        this.checkDate != null &&
        typeof this.checkDate == "function" &&
        !this.checkDate(date)
      )
        return false;

      // next check against mindate if min date is specified
      if (min.isValid() && date.isBefore(min)) return false;

      // otherwise return true
      return true;
      //return this.checkDate != null && typeof(this.checkDate) == 'function' ? this.checkDate(date) : true;
    },

    getCellClasses(cell) {
      var classes = {
        "obe-calendar__date-link": true,
        "obe-calendar__date-link--selected": cell.isSelected,
        "obe-calendar__date-link--trailing": cell.trailing,
      };
      return classes;
    },

    selectDate(date, click) {
      var result = this.clampToMinDate(date);

      // only update value if date is valid
      if (this.isValidDate(result)) {
        this.$emit("input", result.format(DATE_FORMAT));

        if (click) this.$emit("date:click", result.format(DATE_FORMAT));
      }
    },

    changeMonth(dir) {
      // get new date range
      //var date = moment(this.currentDate);
      //this.selectDate(date.add(dir, 'M'));

      var month = this.month + dir;
      var year = this.year;

      if (month < 0) {
        month = 11;
        year--;
      } else if (month >= 12) {
        month = 0;
        year++;
      }

      this.month = month;
      this.year = year;
    },
  },

  mounted() {
    // set current month/year
    this.month = this.currentDate.month();
    this.year = this.currentDate.year();

    // make sure the initially displayed month is correct
    this.changeMonth(0);
  },
};
</script>

