<template>
  <div>
    <div v-if="loading">
      <div class="obe-db-heading">Loading appointment information...</div>
    </div>

    <template v-else>
      <div class="obe-appt-title">
        <div class="obe-appt-title__main">
          <div class="obe-db-flex-title">
            <div class="flex-title-icon">
              <span class="obe-i-calendar2"></span>
            </div>
            <div class="flex-title-main">
              {{
                appointment.arrival_window_start_time
                  | dateFormat("DD MMM YYYY")
              }}
              <span class="flex-title-time">
                Contact: {{ this.appointment.contact.name }}
              </span>

              <span class="flex-title-sub" v-if="appointment.address">
                {{ appointment.address.street }},
                {{ appointment.address.city }} {{ appointment.address.state }},
                {{ appointment.address.postal_code }}
              </span>

              <span class="flex-title-sub" v-else
                >(Appointment address is empty, this message will be removed in
                production)</span
              >
            </div>
          </div>
        </div>

        <div class="obe-appt-title__buttons">
          <push-button
            wide
            color="text"
            :to="`/booking_summary/${appointment.id}`"
            >Booking Summary</push-button
          >
          <push-button outline wide color="gray" @click="showQuote = !showQuote"
            >Estimate</push-button
          >
        </div>
      </div>

      <!-- STAFF OVERRIDE FIELDS IF REQUIRED -->
      <override-fields v-if="currentUser.is_staff_session" />

      <!-- MAIN APPOINTMENT/ADDON DETAILS -->
      <div class="obe-appt-wrapper">
        <div class="obe-appt-wrapper__main">
          <div class="obe-appt-main">
            <div class="obe-appt-tabs">
              <div class="obe-appt-tabs__col">
                <div class="obe-appt-tabs__col-label">Time Preference</div>
                <div class="obe-appt-tabs__col-content">
                  <dropdown
                    v-model="time"
                    :items="availableTimeOptions"
                    :disabled="!canEdit"
                  ></dropdown>
                </div>
              </div>
              <div class="obe-appt-tabs__col">
                <div class="obe-appt-tabs__col-label">Type of Cleaning</div>
                <div class="obe-appt-tabs__col-content">
                  {{ booking.service_type | capitalizeWords }}
                </div>
              </div>
              <div class="obe-appt-tabs__col">
                <div class="obe-appt-tabs__col-label">Frequency</div>
                <div class="obe-appt-tabs__col-content">
                  {{ booking.cleaning_freq | capitalize }}
                </div>
              </div>
              <div class="obe-appt-tabs__col">
                <div class="obe-appt-tabs__col-label">Day of the Week</div>
                <div class="obe-appt-tabs__col-content">{{ dayOfWeek }}</div>
              </div>
              <div class="obe-appt-tabs__col">
                <div class="obe-appt-tabs__col-label">Person-Hours</div>
                <div class="obe-appt-tabs__col-content">
                  {{ calculatePersonHours }}
                </div>
              </div>
              <div class="obe-appt-tabs__col">
                <div class="obe-appt-tabs__col-label">
                  Duration<br />(Team of 3)
                </div>
                <div class="obe-appt-tabs__col-content">
                  {{ calculateDurationTeamOfThree | duration }}
                </div>
              </div>
            </div>

            <div class="obe-appt-main__addons">
              <div class="obe-addon-heading">
                Changes to Time preference and cleaning Add-ons can only be made
                more than 48 hours prior to cleaning appointment.<br />
                If you must make any changes within 48 hours, please contact our
                customer care at 1-855-925-9900.
                <!--
								Current cleaning job add-ons
								<span class="obe-addon-heading__sub">Cannot be modified within 48 hours of appointment date</span>
								-->
              </div>

              <!-- ADDON LIST -->
              <div
                class="obe-addons obe-addons--dashboard"
                :class="{ 'obe-addons--frozen': !canEdit }"
              >
                <template v-for="addon in visibleAddons">
                  <!-- Display icon for laundry service addon -->
                  <div
                    v-if="
                      addon.addon_name == 'linen_laundry_gold' ||
                      addon.addon_name == 'linen_laundry_silver'
                    "
                    :key="addon.addon_name"
                    class="obe-addons__col"
                  >
                    <div class="obe-addons__item">
                      <div class="obe-addons__controls" v-if="canEdit">
                        <a
                          href="#"
                          class="obe-addons__delete"
                          @click.prevent="removeAddon(addon)"
                        >
                          <span class="obe-sr-only">Delete Addon</span>
                          <span class="obe-i-delete"></span>
                        </a>
                        <a
                          href="#"
                          class="obe-addons__edit"
                          @click.prevent="laundryForm = true"
                        >
                          <span class="obe-sr-only">Edit Addon</span>
                          <span class="obe-i-edit-pencil"></span>
                        </a>
                      </div>

                      <div class="obe-addons__option">
                        <div class="obe-addons__option-button">
                          <span
                            class="obe-addons__option-icon"
                            :class="$options.addons.laundry_options.icon"
                          ></span>
                        </div>
                        <span class="obe-addons__option-label">
                          {{ $options.addons.laundry_options.label }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <!-- Display icon for normal addons -->
                  <div
                    v-else-if="$options.addons[addon.addon_name]"
                    :key="addon.addon_name"
                    class="obe-addons__col"
                  >
                    <div class="obe-addons__item">
                      <div class="obe-addons__controls" v-if="canEdit">
                        <a
                          href="#"
                          class="obe-addons__delete"
                          @click.prevent="removeAddon(addon)"
                        >
                          <span class="obe-sr-only">Delete Addon</span>
                          <span class="obe-i-delete"></span>
                        </a>
                        <a
                          v-if="addonEditable(addon)"
                          href="#"
                          class="obe-addons__edit"
                          @click.prevent="addonClicked(addon)"
                        >
                          <span class="obe-sr-only">Edit Addon</span>
                          <span class="obe-i-edit-pencil"></span>
                        </a>
                      </div>

                      <div class="obe-addons__option">
                        <div class="obe-addons__option-button">
                          <span
                            class="obe-addons__option-icon"
                            :class="$options.addons[addon.addon_name].icon"
                          ></span>
                        </div>
                        <span class="obe-addons__option-label">
                          {{ $options.addons[addon.addon_name].label }}
                        </span>
                      </div>
                    </div>
                  </div>
                </template>

                <!-- Display new addon button -->
                <div class="obe-addons__col" v-if="canEdit && showNewAddonBtn">
                  <div class="obe-addons__item">
                    <a
                      href="#"
                      class="obe-addons__new-option"
                      @click.prevent="addonSelector = true"
                    >
                      <div class="obe-addons__option-button">
                        <span class="obe-addons__option-icon obe-i-plus"></span>
                      </div>
                      <span class="obe-addons__option-label"> Add-ons </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="obe-appt-main__comments">
              <text-area
                v-model="comments"
                :maxlength="maxCharacterLimit"
                class="obe-appt-main__comments-field"
                :disabled="!canEdit"
                placeholder="Notes to cleaning team"
              ></text-area>
              <div class="ml-2 help-text-cleaning-notes">
                Characters left: {{ this.charactersLeft }}
              </div>
            </div>
          </div>

          <!-- HOME ACCESS INFO -->
          <home-access ref="homeAccess" :can-save="canEdit"></home-access>

          <!-- ASSOCIATED PRODUCTS -->
          <products ref="products"></products>
        </div>

        <div class="obe-appt-wrapper__right" v-if="showQuote">
          <estimate-info></estimate-info>
        </div>
      </div>

      <!-- ACTIONS -->
      <div class="obe-appt-buttons">
        <!-- BOTTOM BUTTON OPTIONS FOR ONE-TIME CLEANINGS -->
        <div
          class="obe-appt-buttons__col"
          v-if="booking.cleaning_freq.toLowerCase() == 'one-time'"
        >
          <ul class="obe-button-menu obe-button-menu--left">
            <li>
              <push-button
                wide
                outline
                :disabled="saving"
                color="gray"
                @click="reordering = true"
                >Reorder booking</push-button
              >
            </li>

            <template v-if="canReschedule">
              <li>
                <push-button
                  wide
                  outline
                  :disabled="saving"
                  color="gray"
                  @click="rescheduling = true"
                  >Reschedule</push-button
                >
              </li>

              <!-- if this is an initial appt of a regular schedule then bring up the skip initial prompt, otherwise use the regular cancellation dialog -->
              <li v-if="appointment.initial_mp">
                <push-button
                  wide
                  outline
                  :disabled="saving"
                  color="gray"
                  @click="skipInitialAppt = true"
                  >Cancel</push-button
                >
              </li>

              <li v-else>
                <push-button
                  wide
                  outline
                  :disabled="saving"
                  color="gray"
                  @click="cancelAppt = true"
                  >Cancel</push-button
                >
              </li>
            </template>
          </ul>
        </div>

        <!-- BOTTOM BUTTON OPTIONS FOR REGULAR CLEANING APPOINTMENTS -->
        <div
          class="obe-appt-buttons__col"
          v-else-if="
            booking.cleaning_freq.toLowerCase() != 'one-time' && canReschedule
          "
        >
          <ul class="obe-button-menu obe-button-menu--left">
            <!--<li><push-button wide outline :disabled="saving" color="gray" @click="reordering = true">Reorder booking</push-button></li>-->
            <li
              v-if="booking.is_staff_override && currentUser.is_staff_session"
            >
              <push-button
                wide
                outline
                :disabled="saving"
                color="gray"
                @click="reordering = true"
                >Reorder booking</push-button
              >
            </li>
            <li>
              <push-button
                outline
                :style="{ padding: '10px 16px', whiteSpace: 'nowrap' }"
                :disabled="saving"
                color="gray"
                @click="skipRegularAppt = true"
                >Skip or Reschedule this job</push-button
              >
            </li>
            <li>
              <push-button
                wide
                outline
                :disabled="saving"
                color="gray"
                @click="cancelSchedule = true"
                >Cancel schedule</push-button
              >
            </li>
          </ul>
        </div>

        <!-- SAVE/BACK BUTTONS -->
        <div class="obe-appt-buttons__col">
          <ul class="obe-button-menu obe-button-menu--right">
            <li>
              <push-button wide color="gray" :disabled="saving" to="/schedule"
                >Back</push-button
              >
            </li>

            <!-- Trigger update options prompt for recurring appointments -->
            <li v-if="canEdit && isRecurring">
              <push-button
                wide
                color="orange"
                :loading="saving"
                @click="showUpdateOptions = true"
                >Save changes</push-button
              >
            </li>

            <!-- otherwise just save the appointment for one-time appointments -->
            <li v-else-if="canEdit && !isRecurring">
              <push-button wide color="orange" :loading="saving" @click="submit"
                >Save changes</push-button
              >
            </li>
          </ul>
        </div>
      </div>
    </template>

    <!-- normal addon details popup -->
    <modal
      :show="addonFormType == 'normal'"
      @update:show="closeAddonForm(!$event)"
      max-width="800px"
    >
      <addon-details
        v-if="addonFormType == 'normal'"
        @closed="closeAddonForm(true)"
      ></addon-details>
    </modal>

    <!-- laundry addon details popup -->
    <modal :show.sync="laundryForm" max-width="800px">
      <laundry-options
        v-if="laundryForm"
        @closed="laundryForm = false"
      ></laundry-options>
    </modal>

    <!-- selector for adding new addons -->
    <modal :show.sync="addonSelector" max-width="800px">
      <addon-selector
        v-if="addonSelector"
        @selected="addonSelected"
        @closed="addonSelector = false"
      ></addon-selector>
    </modal>

    <!-- remove addon interface -->
    <modal
      :show="removingAddon != null"
      max-width="800px"
      @update:show="removingAddon = $event ? removingAddon : null"
    >
      <remove-addon
        v-if="removingAddon != null"
        :name="removingAddon"
        @closed="removingAddon = null"
      ></remove-addon>
    </modal>

    <!-- late change request popup -->
    <modal :show="showLateRequestForm" max-width="730px">
      <request-changes-form></request-changes-form>
    </modal>

    <!-- skip appointment form -->
    <modal :show.sync="skipAppt" max-width="800px">
      <skip-appointment
        v-if="skipAppt"
        @closed="skipAppt = false"
      ></skip-appointment>
    </modal>

    <!-- cancel one-time appt form -->
    <modal :show.sync="cancelAppt" max-width="800px">
      <cancel-appointment
        v-if="cancelAppt"
        @closed="cancelAppt = false"
      ></cancel-appointment>
    </modal>

    <!-- skip regular appointment form -->
    <modal :show.sync="skipRegularAppt" max-width="800px">
      <skip-regular-appt
        v-if="skipRegularAppt"
        @closed="skipRegularAppt = false"
      ></skip-regular-appt>
    </modal>

    <!-- skip initial cleaning form prompt -->
    <modal :show.sync="skipInitialAppt" max-width="800px">
      <skip-initial-appt
        v-if="skipInitialAppt"
        @closed="skipInitialAppt = false"
      ></skip-initial-appt>
    </modal>

    <!-- cancellation form -->
    <modal :show.sync="cancelSchedule" max-width="800px">
      <cancel-schedule
        v-if="cancelSchedule"
        @closed="cancelSchedule = false"
      ></cancel-schedule>
    </modal>

    <!-- cancellation form -->
    <modal :show.sync="rescheduling" max-width="850px">
      <reschedule-popup
        v-if="rescheduling"
        @closed="rescheduling = false"
      ></reschedule-popup>
    </modal>

    <!-- reordering form -->
    <modal :show.sync="reordering" max-width="800px">
      <reorder-booking-popup
        v-if="reordering"
        @closed="reordering = false"
      ></reorder-booking-popup>
    </modal>

    <!-- update options popup when updating a regular cleaning appointment -->
    <modal :show.sync="showUpdateOptions" max-width="800px">
      <update-options v-if="showUpdateOptions" @closed="updateOptionsClosed" />
    </modal>
  </div>
</template>

<script>
import _ from "lodash/function";
import moment from "moment";
import { mapState, mapGetters } from "vuex";

import filters from "obe/shared/filters";
import alerts from "obe/shared/alerts";
import {
  freqOptions,
  addonList,
  serviceTypeAddons,
  timeOptions,
} from "obe/screens/booking-form/info";

import types from "../store/types";

import AddonDetails from "./AddonDetails.vue";
import AddonSelector from "./AddonSelector.vue";
import RemoveAddon from "./RemoveAddon.vue";
import LaundryOptions from "./LaundryOptions.vue";
import RequestChangesForm from "./RequestChangesForm.vue";
import SkipAppointment from "./SkipAppointment.vue";
import SkipInitialAppt from "./SkipInitialAppt.vue";
import CancelSchedule from "./CancelSchedule.vue";
import EstimateInfo from "./EstimateInfo.vue";
import ReschedulePopup from "./ReschedulePopup.vue";
import SkipRegularAppt from "./SkipRegularAppt.vue";
import CancelAppointment from "./CancelAppointment.vue";
import ReorderBookingPopup from "./ReorderBookingPopup.vue";
import UpdateOptions from "./UpdateOptions.vue";
import BlockSection from "./BlockSection.vue";
import HomeAccess from "./HomeAccess.vue";
import Products from "./Products.vue";
import OverrideFields from "./OverrideFields.vue";

// when these mutations are executed update the quote
const watchMutations = [
  types.UPDATE_ADDON,
  types.REMOVE_ADDON,
  types.DELETE_ADDON,
];

// when these mutations are executed set the isDirty flag to true
const dirtyingMutations = [
  types.UPDATE_APPT_ACCESS,
  types.UPDATE_ADDON,
  types.REMOVE_ADDON,
  types.DELETE_ADDON,
];

const DATE_FORMAT = "YYYY-MM-DD";

const now = moment();
const minDate = moment(now).startOf("month");
const maxDate = moment(minDate).add(4, "M").startOf("month");

export default {
  addons: addonList,
  serviceTypeAddons,
  freqOptions,
  timeOptions,

  mixins: [filters],
  inject: ["$showUnsavedWarning"],

  provide() {
    return {
      $showRescheduleForm: this.showRescheduleForm,
      $showSkipForm: this.showSkipForm,
      $showApptCancelForm: this.showApptCancelForm,
    };
  },

  components: {
    AddonDetails,
    AddonSelector,
    RemoveAddon,
    LaundryOptions,
    RequestChangesForm,
    SkipAppointment,
    CancelSchedule,
    EstimateInfo,
    ReschedulePopup,
    SkipRegularAppt,
    ReorderBookingPopup,
    HomeAccess,
    BlockSection,
    Products,
    CancelAppointment,
    SkipInitialAppt,
    UpdateOptions,
    OverrideFields,
  },

  props: {
    id: { type: String, required: true },
  },

  data() {
    return {
      reordering: false,
      showQuote: false,
      loading: true,
      saving: false,
      rescheduling: false,
      showUpdateOptions: false,
      time: "",
      comments: "",
      laundryForm: false,
      removingAddon: null,
      addonSelector: false,
      skipAppt: false,
      cancelSchedule: false,
      skipRegularAppt: false,
      skipInitialAppt: false,
      cancelAppt: false,
      changesMade: [],
      isDirty: false,
      maxCharacterLimit: 1000,
      movingServiceDefaultAddonKeys: [
        "marks_on_walls",
        "cupboards_and_closets",
      ],
    };
  },

  computed: {
    ...mapGetters(["getBookingOptionsByRoom"]),
    ...mapState({
      currentUser: (state) => state.dashboard.currentUser,
      booking: (state) => state.schedule.apptBooking,
      workOrder: (state) => state.schedule.apptWorkOrder,
      address: (state) => state.schedule.apptAddress,
      appointment: (state) => state.schedule.currentAppt,
      rawAddons: (state) => state.schedule.apptAddons,
      currentAddon: (state) => state.schedule.currentAddon,
      roomOptions: (state) => state.schedule.roomOptions,
      isRecurring: (state) => state.schedule.isRecurring,
      estimate: (state) => state.schedule.estimate,
      availGrades: (state) => state.schedule.availGrades,
      overrides: (state) => state.schedule.overrides,
      baseOverrides: (state) => state.schedule.overrides,
    }),

    addons: function () {
      return this.filterAddons(this.rawAddons);
    },

    showNewAddonBtn() {
      var names = [
        "oven_inside",
        "fridge_inside",
        "windows_inside",
        "cupboards_and_closets",
        "balcony_sweeping",
        "blinds_wiped",
        "walls_washed",
        "marks_on_walls",
        "dishes_washed",
        "linen_change",
        "inspection",
      ];

      var checkAddon = (addon) => !addon || addon.remove_on !== false;

      for (var n of names) if (checkAddon(this.addons[n])) return true;

      if (
        checkAddon(this.addons.linen_laundry_gold) &&
        checkAddon(this.addons.linen_laundry_silver)
      )
        return true;

      return false;
    },

    dayOfWeek() {
      var arrival = moment(this.appointment.arrival_window_start_time);
      return arrival.format("dddd");
    },

    visibleAddons() {
      var result = [];

      //if (this.addons[name].remove_on === false)
      for (var name in this.addons) result.push(this.addons[name]);

      return result;
    },

    addonFormType() {
      var laundry = ["linen_laundry_gold", "linen_laundry_silver"];

      if (this.currentAddon == null) return "";
      else if (addonList[this.currentAddon.addon_name] != null) return "normal";
      else if (laundry.indexOf(this.currentAddon.addon_name) >= 0)
        return "laundry";
      else return "";
    },

    timeBeforeArrival() {
      var arrival = moment(this.appointment.arrival_window_start_time);
      var now = moment();
      var diff = moment.duration(arrival.diff(now));

      return diff;
    },

    isUpcoming() {
      return (
        ["none", "new", "accepted", "scheduled"].indexOf(
          this.appointment.status.toLowerCase()
        ) >= 0
      );
    },

    canEdit() {
      if (this.appointment) {
        var arrival = moment(this.appointment.arrival_window_start_time);
        var today = moment();

        // can't edit if this appointment was created by a staff member and we're not logged in with one
        // if (this.booking.is_staff_override && !this.currentUser.is_staff_session)
        // 	return false;

        // can't edit if appointment is past the current date
        if (today.isSameOrAfter(arrival)) return false;

        // can't edit it within 48 hour window
        if (this.timeBeforeArrival.asHours() <= 48) {
          if (this.currentUser.is_staff_session) {
            return true;
          }
          return false;
        }

        // can only edit if status is an 'upcoming' status
        if (
          ["none", "new", "accepted", "scheduled"].indexOf(
            this.appointment.status.toLowerCase()
          ) == -1
        )
          return false;

        // all checks passed!
        return true;
      } else {
        return false;
      }
    },

    canReschedule() {
      // if (this.booking.is_staff_override && !this.currentUser.is_staff_session)
      // {
      // 	return false;
      // }
      // else
      // {
      // 	var date = moment(this.appointment.arrival_window_start_time).startOf('day');
      // 	return this.isUpcoming && moment().isBefore(date);
      // }

      var date = moment(this.appointment.arrival_window_start_time).startOf(
        "day"
      );
      return this.isUpcoming && moment().isBefore(date);
    },

    showLateRequestForm() {
      if (
        this.appointment &&
        this.canEdit &&
        !this.currentUser.is_staff_session
      )
        return this.timeBeforeArrival.asHours() <= 48;
      else return false;
    },

    isScheduled() {
      var scheduled = ["scheduled", "dispatched", "in progress"];
      return scheduled.indexOf(this.appointment.status.toLowerCase()) >= 0;
    },

    arrivalWindow() {
      if (this.isScheduled) {
        //var start = moment(this.item.appt.arrival_window_start_time);
        //var end = moment(this.item.appt.arrival_window_end_time);
        // var start = moment(this.appointment.sched_start_time);
        // var end = moment(this.appointment.sched_end_time);
        // return `${start.format('h:mma')} - ${end.format('h:mma')}`;
        return this.workOrder.arrival_window_for_email;
      } else {
        return "N/A";
      }
    },

    availableTimeOptions() {
      // When grade is Limited then time must be flexible
      let arrivalDate = moment(
        this.appointment.arrival_window_start_time
      ).format("YYYY-MM-DD");
      let availabilityGrade = this.availGrades[arrivalDate];

      if (availabilityGrade && availabilityGrade["name"] === "Limited") {
        const options = this.$options.timeOptions.filter(
          (time) => time.value === "flexible-8am-4pm"
        );

        return options;
      }

      return this.$options.timeOptions;
    },

    charactersLeft() {
      let size = 0;

      if (this.comments) {
        size = this.comments.length;
      }

      const charactersLeft = this.maxCharacterLimit - size;

      return charactersLeft;
    },

    calculatePersonHours() {
      if (this.appointment.is_regular_appointment) {
        if (this.overrides.apply_overrides && this.overrides.regular_duration) {
          return (parseFloat(this.overrides.regular_duration) / 60).toFixed(2);
        }

        return (parseFloat(this.estimate.regular_minutes) / 60).toFixed(2);
      }

      if (this.overrides.apply_overrides && this.overrides.initial_duration) {
        return (parseFloat(this.overrides.initial_duration) / 60).toFixed(2);
      }

      return (parseFloat(this.estimate.initial_minutes) / 60).toFixed(2);
    },

    calculateDurationTeamOfThree() {
      if (this.appointment.is_regular_appointment) {
        if (this.overrides.apply_overrides && this.overrides.regular_duration) {
          return (parseFloat(this.overrides.regular_duration) / 3).toFixed(2);
        }

        return (parseFloat(this.estimate.regular_minutes) / 3).toFixed(2);
      }

      if (this.overrides.apply_overrides && this.overrides.initial_duration) {
        return (parseFloat(this.overrides.initial_duration) / 3).toFixed(2);
      }

      return (parseFloat(this.estimate.initial_minutes) / 3).toFixed(2);
    },
  },

  watch: {
    // if time or comments are modified then set the dirty flag to true
    time() {
      this.isDirty = true;
    },

    comments() {
      this.isDirty = true;
    },
  },

  methods: {
    filterAddons(addons) {
      const isMoving = this.booking.service_type === "moving";

      if (!isMoving) return addons;

      const filteredAddons = {};

      Object.keys(addons).forEach((key) => {
        if (!this.movingServiceDefaultAddonKeys.includes(key)) {
          filteredAddons[key] = addons[key];
        }
      });

      return filteredAddons;
    },

    async loadAvailGrades() {
      this.loading = true;

      try {
        await this.$store.dispatch("schedule.getAvailGrades", {
          earliest_date: minDate.format("YYYY-MM-DD"),
          latest_date: maxDate.format("YYYY-MM-DD"),
        });
      } finally {
        this.loading = false;
      }
    },

    showRescheduleForm() {
      this.skipInitialAppt = false;
      this.skipRegularAppt = false;
      this.rescheduling = true;
    },

    showApptCancelForm() {
      this.skipInitialAppt = false;
      this.skipRegularAppt = false;
      this.$nextTick(() => (this.cancelAppt = true));
    },

    showSkipForm() {
      this.skipRegularAppt = false;
      this.skipAppt = true;
    },

    async loadAppointment() {
      this.loading = true;

      try {
        await this.$store.dispatch("schedule.getAppointment", { id: this.id });
        await this.updateEstimate();
        await this.loadAvailGrades();

        this.comments = this.booking.cleaning_notes;

        if (this.booking.cleaning_freq.toLowerCase() == "one-time")
          this.time = this.booking.initial_arrival_window;
        else this.time = this.booking.regular_arrival_time;
      } finally {
        // reset dirty flag on next tick to avoid Vue reactivity automatically setting this flag to true after it resolves the updates made above
        this.$nextTick(() => (this.isDirty = false));
        this.loading = false;
      }
    },

    async removeAddon(addon) {
      if (
        await alerts.confirm(
          "Are you sure you want to remove this addon?",
          "Removing Addon"
        )
      )
        this.$store.commit(types.DELETE_ADDON, addon.addon_name);

      /*
			if (this.isRecurring)
			{
				this.removingAddon = addon.addon_name;
			}
			else
			{
				if (await alerts.confirm('Are you sure you want to remove this addon?', 'Removing Addon'))
					this.$store.commit(types.REMOVE_ADDON, { name: addon.addon_name, removeOn: 'this' });
			}
			*/
    },

    async updateEstimate() {
      try {
        await this.$store.dispatch("schedule.getEstimate", this.currentUser);
      } finally {
      }
    },

    // return all options without 'no' as an option with option names capitalized
    getAddonOptions(name) {
      var options = this.getBookingOptionsByRoom(name, this.booking.num_rooms);
      return options
        .filter((o) => o.option_name.toLowerCase() != "no")
        .map((o) => o.option_name);
    },

    addonClicked(addon) {
      var options = this.getAddonOptions(addon.addon_name);
      this.$store.commit(types.SET_CURRENT_ADDON, addon);
    },

    closeAddonForm(flag) {
      if (flag) this.$store.commit(types.SET_CURRENT_ADDON, null);
    },

    addonSelected(addon) {
      if (addon.addon_name == "laundry_options") {
        // display laundry settings form
        this.laundryForm = true;
      } else {
        var options = this.getAddonOptions(addon.addon_name);

        // add the addon to state
        this.$store.commit(types.UPDATE_ADDON, addon);

        // open the details popup
        //if (this.isRecurring || options.length > 1)
        if (options.length > 1)
          this.$store.commit(
            types.SET_CURRENT_ADDON,
            this.addons[addon.addon_name]
          );
      }
    },

    addonEditable(addon) {
      var options = this.getAddonOptions(addon.addon_name);
      return options.length > 1;
    },

    updateOptionsClosed(option) {
      // dismiss update options popup
      this.showUpdateOptions = false;

      // if option is non-false then submit
      if (option) this.submit(option);
    },

    async submit(bulkSettings) {
      if (!this.$refs.homeAccess.validate()) {
        // It opens access details blocks if it returns invalid.
        this.$refs.homeAccess.toggleVisibility(false);
        return;
      }

      this.saving = true;

      try {
        var params = {
          time: this.time,
          comments: this.comments,
        };

        if (this.isRecurring && bulkSettings) {
          params.update_on = bulkSettings.updateOn;
          params.force_override = bulkSettings.forceOverride;
        }

        await this.$store.dispatch("schedule.updateAppointment", params);

        await alerts.show(
          "Appointment updated successfully!",
          "Success",
          "success"
        );

        // set dirty flag to false so the navigation guard does not trigger when the page reloads
        this.isDirty = false;
        location.reload();
      } finally {
        this.saving = false;
      }
    },

    beforeUnload(event) {
      if (this.isDirty) {
        var msg =
          "There are unsaved changes, are you sure you want to leave this tab?";

        event.preventDefault();
        event.returnValue = msg;
        return msg;
      }
    },
  },

  mounted() {
    this.loadAppointment();

    // automatically update estimate whenever addons are aded/removed
    this.stateWatcher = function (mutation, state) {
      if (watchMutations.indexOf(mutation.type) >= 0) {
        this.updateEstimate();
        this.showQuote = true;
      }

      if (dirtyingMutations.indexOf(mutation.type) >= 0) this.isDirty = true;
    }.bind(this);

    this.$store.subscribe(this.stateWatcher);

    // add before unload handler
    this.beforeUnloadHandler = this.beforeUnload.bind(this);
    window.addEventListener("beforeunload", this.beforeUnloadHandler);
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeUnloadHandler);
  },

  deactivated() {
    this.$store.unsubscribe(this.stateWatcher);
  },

  beforeRouteLeave(to, from, next) {
    if (this.isDirty) {
      this.$showUnsavedWarning().then((result) => {
        if (result) next();
      });
    } else {
      next();
    }
  },
};
</script>
