<template>
  <form-field v-bind="formFieldAttrs" :validate-value="value">
    <div class="obe-text-field-wrapper">
      <textarea
        ref="textbox"
        v-if="multiline"
        class="obe-text-field obe-text-field--textarea"
        :class="inputClasses"
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        :maxlength="maxlength"
        @keypress="checkEnter"
        @input="$emit('input', $event.target.value)"
      >
      </textarea>

      <input
        v-else-if="mask == null"
        ref="textbox"
        :type="inputType"
        class="obe-text-field"
        :class="inputClasses"
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        :autofocus="autofocus"
        :maxlength="maxlength"
        @keypress="checkEnter"
        @input="$emit('input', $event.target.value)"
      />

      <masked-input
        v-else-if="mask != null"
        masked
        :mask="mask"
        :type="inputType"
        class="obe-text-field"
        :class="inputClasses"
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        :maxlength="maxlength"
        @keypress="checkEnter"
        @input="$emit('input', $event)"
      >
      </masked-input>

      <span
        v-if="icon != null && icon != ''"
        class="obe-text-field-wrapper__icon"
        :class="icon"
      ></span>
    </div>
  </form-field>
</template>

<script>
import { TheMask as MaskedInput } from "vue-the-mask";
import formFieldProps from "../mixins/form-field";

export default {
  components: { MaskedInput },

  mixins: [formFieldProps],

  props: {
    inputType: { type: String, default: "text" },
    inputClasses: { type: [String, Array, Object], default: "" },
    value: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    multiline: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
    mask: { type: String, default: null },
    icon: { type: String, default: null },
    maxlength: { type: Number, default: null },
  },

  methods: {
    checkEnter(event) {
      if (event.which == 13) this.$emit("enter");
    },
  },

  mounted() {
    if (this.autofocus && this.$refs.textbox) {
      this.$nextTick(() => this.$refs.textbox.focus());
    }
  },
};
</script>
