import Vue from "vue";

// Log errors to sentry
import * as Sentry from "@sentry/vue";

import "obe/shared/breakpoints";

// import router/store
import router from "./dashboard/router";
import store from "./dashboard/store";

// import main OBE component
import Dashboard from "./dashboard/comp/Dashboard.vue";

// import core UX components
import "./ux";

// import screens
import "./dashboard/screens/login";
import "./dashboard/screens/account";
import "./dashboard/screens/schedule";
import "./dashboard/screens/payments";
import "./dashboard/screens/catalog";
//import './dashboard/screens/addresses';
import "./dashboard/screens/contacts";
import "./dashboard/screens/feedback";
import "./dashboard/screens/help";
import "./dashboard/screens/signup";

// look for currently authenticated user

if (window._spa_data) {
  if (window._spa_data.customer)
    store.commit("SET_CURRENT_USER", window._spa_data.customer);

  if (window._spa_data.roomOptions)
    store.commit("SET_ROOM_OPTIONS", window._spa_data.roomOptions);

  // load franchise data into state
  store.commit(
    "SET_DASHBOARD_FRANCHISES",
    JSON.parse(JSON.stringify(window._spa_data.franchises))
  );

  // initialize upcoming order count
  store.commit(
    "SET_DASHBOARD_UPCOMING_ORDERS",
    window._spa_data.upcomingOrders || 0
  );
}

// Initialize error logging through Sentry
Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "https://stage.obeclean.com",
        "https://booking.aspenclean.com",
      ],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// to allow easy access from all areas append to window:
window.Sentry = Sentry;

// return OBE VM
export default new Vue({
  router,
  store,

  data: {
    props: {},
  },

  render(h) {
    return h(Dashboard, { props: this.props });
  },
});
