<template>
  <div :class="classes">
    <a href="#" class="obe-help-item__title" @click.prevent="expand">{{
      item.title
    }}</a>
    <div v-if="item.title !== 'FAQ'" class="obe-help-item__content">
      {{ item.content }}
    </div>
    <div v-else class="obe-help-item__content">
      <a :href="item.content" target="_blank" style="color: #7e7e7e"
        >https://aspenclean.com/pages/faq-services</a
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import types from "../store/types";

export default {
  props: {
    item: { type: Object, required: true },
  },

  computed: {
    ...mapState({
      currentItem: (state) => state.help.expandedItem,
    }),

    classes() {
      var cls = "obe-help-item";

      if (this.currentItem == this.item.id) cls += " obe-help-item--expanded";

      return cls;
    },
  },

  methods: {
    expand() {
      var id = this.currentItem == this.item.id ? 0 : this.item.id;
      this.$store.commit(types.SET_EXPANDED_HELP_ITEM, id);
    },
  },
};
</script>

